<template>
  <Step :step="2" />
  <div class="container page">
    <div class="box">
      <div class="form-group">
        <HeaderStep :title="$t('minStep.step2')" :step="2" id="s1"></HeaderStep>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.discount }" id="s3">
            <label class="form-field">
              {{ $t("info.discount") }}
              <span v-if="error.discount">{{ error.discount }}</span>
            </label>

            <Multiselect
              v-if="detail.carType == '私人車輛'"
              class="form-input"
              v-model="detail.discount"
              :options="options.personDiscount"
              @select="check('discount')"
              :allowEmpty="false"
            />

            <Multiselect
              v-else
              class="form-input"
              v-model="detail.discount"
              :options="options.companyDiscount"
              @select="check('discount')"
              :allowEmpty="false"
            />
          </div>

          <div class="form-item" :class="{ error: error.driverOld }" id="s4">
            <label class="form-field">
              {{ $t("info.driver.old") }}
              <span v-if="error.driverOld">{{ error.driverOld }}</span>
            </label>
            <input
              class="form-input"
              v-model="driver.old"
              @blur="check('driverOld')"
              type="number"
            />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.driverYears }" id="s5">
            <label class="form-field">
              {{ $t("info.driver.years") }}
              <span v-if="error.driverYears">{{ error.driverYears }}</span>
            </label>
            <input
              class="form-input"
              v-model="driver.years"
              @blur="check('driverYears')"
              type="number"
            />
          </div>

          <div class="form-item" :class="{ error: error.driverJob }" id="s6">
            <label class="form-field">
              {{
                detail.setterType == "person"
                  ? $t("info.driver.job")
                  : $t("info.driver.trade")
              }}
              <span v-if="error.driverJob">
                {{ error.driverJob }}
              </span>
            </label>

            <Multiselect
              class="form-input search"
              v-model="driver.job"
              :options="options.jobs"
              :allowEmpty="false"
              track-by="label"
              :searchable="true"
              @select="check('driverJob')"
              :placeholder="$t('info.search')"
            />

            <input type="hidden" v-model="driver.job" ref="job" />
          </div>
        </div>
      </div>
    </div>

    <div class="form-action">
      <button class="btn btn-prev" @click="prev">
        {{ $t("info.back") }}
      </button>
      <button class="btn btn-next" @click="next">
        {{ $t("info.next") }}
      </button>
    </div>
  </div>
</template>
<script>
import Step from "../../components/Step";
import HeaderStep from "../../components/HeaderStep";
import Multiselect from "@vueform/multiselect";

import util from "../../helper/util";
import { mapState } from "vuex";

import jobs from "../../data/jobs";

import { scroller } from "vue-scrollto/src/scrollTo";
const scrollTo = scroller();

export default {
  name: "Driver",

  components: {
    Step,
    HeaderStep,
    Multiselect,
  },

  computed: {
    ...mapState({
      cache: (state) => state.detail.data,
    }),
  },

  data() {
    return {
      detail: {
        type: "",
        carType: "",
        brand: "",
        model: "",
        money: "",
        displacement: "",
        quality: "",
        year: "",
        date: "",
        discount: "",
        setterType: "person",
      },

      driver: {
        old: "",
        years: "",
        job: "",
      },

      error: {
        setterType: false,
        discount: false,

        driverJob: false,
        driverOld: false,
        driverYears: false,
      },

      options: {
        jobs: Object.values(jobs),

        companyDiscount: ["0%", "10%", "20%", "30%"],

        personDiscount: ["0%", "20%", "30%", "40%", "50%", "60%"],
      },
    };
  },

  mounted() {
    this.detail = Object.assign(this.detail, this.cache.detail);
    this.driver = Object.assign(this.driver, this.cache.driver);
  },

  methods: {
    prev() {
      this.$router.push("/info");
    },

    next() {
      if (!this.check()) {
        return;
      }

      this.$store.commit("detail/setDetail", this.detail);
      this.$store.commit("detail/setDriver", this.driver);
      this.$store.commit("detail/setCache");

      this.$router.push("/contact");
    },

    check(field) {
      let rules = {
        setterType: {
          model: this.detail.setterType,
          ref: "#s1",
          message: this.$t("info.verify.setterType"),
          fn: "require",
        },
        discount: {
          model: this.detail.discount,
          ref: "#s2",
          message: this.$t("info.verify.discount"),
          fn: "require",
        },
        driverOld: {
          model: this.driver.old,
          ref: "#s3",
          message: this.$t("info.verify.driverOld"),
          fn: "number",
        },
        driverYears: {
          model: this.driver.years,
          ref: "#s4",
          message: this.$t("info.verify.ownerYears"),
          fn: "number",
        },
        driverJob: {
          model: this.driver.job,
          ref: "#s5",
          message:
            this.detail.setterType == "person"
              ? this.$t("info.verify.job")
              : this.$t("info.verify.trade"),
          fn: "require",
        },
      };

      if (field) {
        let rule = rules[field];
        rules = {};
        rules[field] = rule;
      }

      let result = true;
      for (let key in rules) {
        let rule = rules[key];

        if (rule.before && !rule.before()) {
          this.error[key] = false;
          continue;
        }

        if (rule.ref && !util[rule.fn](rule.model)) {
          this.error[key] = rule.message;

          if (!field && result) {
            scrollTo(rule.ref);
          }

          result = false;
        } else {
          this.error[key] = false;
        }
      }

      return result;
    },
  },
};
</script>
<style lang="less" scoped>
:deep(.multiselect) {
  &.search {
    padding-left: 45px !important;
    position: relative;

    &::before {
      content: "";
      width: 25px;
      height: 25px;
      display: block;
      position: absolute;
      z-index: 1002;
      top: 5px;
      left: 10px;
      background: url("../../assets/images/search.png") no-repeat center;
      background-size: 25px;
    }

    .multiselect-search {
      padding-left: 45px;
      background: #eee;
    }

    .multiselect-placeholder,
    .multiselect-single-label {
      left: 35px;
    }
  }
}

.driver-title {
  font-size: 16px;
  margin: 0 0 16px 0;
}

.add {
  line-height: 16px;
  cursor: pointer;

  * {
    vertical-align: bottom;
  }

  i {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: inline-block;
    background: url("../../assets/images/add.png") center no-repeat;
    background-size: 16px;
  }
}

.remove {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: inline-block;
  background: url("../../assets/images/reduce.png") center no-repeat;
  background-size: 16px;
  cursor: pointer;
}

.q-answer {
  span::before {
    left: 35px !important;
  }
}
</style>