const zhHK = {
    logging: "登入中...",
    loginError: "登入失敗，請重試",
    yes: "是",
    no: "否",
    loading: "提交中，請稍後...",

    error: {
        netword: "網絡錯誤，請重試上載"
    },

    header: {
        about: "關於我們",
        introduction: "公司簡介",
        partner: "合作夥伴",
        quote: "保險報價",
        claim: "裝修保險",
        domestic: "家傭保險",
        home: "家居保險",
        travel: "旅遊保險",
        owner: "置樂保",
        expressTravel: "特快旅遊保",
        helperTravel: "特快家傭保",
        bziPartner: "商業夥伴",
        konwledge: "保險知識",
        contactUs: "聯絡我們",
        login: "登入",
        logout: "登出",
        hello: "您好！"
    },

    home: {
        referer: "由以下平臺轉介",
        submit: "立即投保",
    },

    step: {
        title: "汽車保險",
        step1: "填寫汽車資料",
        step2: "填寫主要駕駛者資料",
        step3: "聯絡資料",
        step4: "完成"
    },

    minStep: {
        step1: "汽車資料",
        step2: "主要駕駛者資料",
        step3: "聯絡資料",
        step4: "完成"
    },

    contact: {
        title: "聯絡資料",
        name: "聯絡人姓名",
        email: "電郵地址",
        mobile: "手機號碼",
        desc: "*暫保單將透過電郵傳送",
        agree_1: "本人/我們確認已閱讀並理解貴公司的",
        agree_2: "條款和條件",
        agree_3: "聲明。",
        agree_4: "收集個人資料",
        agree_5: "本人同意接受其他產品的推廣優惠及資訊。",

        verify: {
            name: "請輸入聯絡人姓名",
            email: "請輸入正確的電郵地址",
            mobile: "請輸入正確的電話號碼"
        }
    },

    info: {
        title: "汽車保險",
        detail: "汽車資料",
        type: "保險類別",
        brand: "品牌",
        model: "汽車型號",
        carType: "汽車類別",
        money: "全保金額",
        quality: "載重噸數",
        discount: "無索償折扣",
        limit: "第三者財物損毀限額",
        date: "起保日期",
        displacement: "車輛排量",
        year: "出廠年份",
        setterType: "投保人性質",
        search: "請輸入搜尋",

        continue: "繼續投保",
        back: "返回",
        next: "下一步",
        state: "私隱政策",
        trem: "條款及細則",
        stateShow: "收集個人資料聲明",
        onlyEn: "只提供英文版本",

        driver: {
            title: "駕駛者",
            name: "駕駛者姓名",
            old: "駕駛者年齡",
            years: "駕駛年資",
            job: "駕駛者職業",
            trade: "公司行業"
        },

        options: {
            type: {
                all: "汽車全保",
                other: "汽車第三者責任保險"
            },

            carType: {
                personal: "私人車輛",
                business: "商用車輛"
            },

            setterType: {
                person: "個人",
                company: "公司"
            }
        },

        verify: {
            detail: "請回答所有問題",
            type: "請輸入保險類別",
            brand: "請輸入品牌",
            model: "請輸入汽車型號",
            carType: "請選擇汽車類別",
            money: "請輸入全保金額",
            quality: "請輸入載重噸數",
            discount: "請選擇無索償折扣",
            limit: "請選擇第三者財物損毀限額",
            date: "請選擇起保日期",
            displacement: "請輸入車輛排量",
            year: "請輸入出廠年份",
            setterType: "請選擇投保人性質",
            job: "請選擇職業",
            ownerYears: "請填寫駕駛年資",
            trade: "請選擇行業",
            driverName: "請輸入正確的姓名",
            driverOld: "請輸入駕駛者年齡",

            trem: "請先確認已閱讀並理解條款和條件聲明。",
            privacy: "請先確認已閱讀並理解收集個人資料聲明。"
        }
    },

    detail: {
        t0: "本人確認以上資料正確無誤。",
        t1: "本人已閱讀並同意大新保險的個人資料收集聲明。",
        t2: "本人授權大新保險代表本人向過往保險公司索取有關以往之保險索償記錄。",
        t3: "本人同意大新保險使用我的個人資料作推廣資訊及直接促銷用途。",
        agree: "請確認條款及細則",
        clear: "清除",
        signEmpty: "請在簽名檔中簽名"
    }
};

export default zhHK;