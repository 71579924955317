import { createRouter, createWebHistory } from "vue-router";

import Home from "./pages/Home";
import Info from "./pages/Info";
import Contact from "./pages/Contact";
import Driver from "./pages/Driver";
import Login from "./pages/Login";
import Success from "./pages/Success";
import Error from "./pages/Error";

const routes = [
    {
        path: "/",
        component: Home
    },
    {
        path: "/info",
        component: Info
    },
    {
        path: "/contact",
        component: Contact
    },
    {
        path: "/driver",
        component: Driver
    },
    {
        path: "/error",
        component: Error
    },
    {
        path: "/success/:id",
        component: Success
    },
    {
        path: "/login",
        component: Login
    }
];

const VueRouter = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior() {
        return { top: 0 };
    }
});

export default VueRouter;