<template>
  <div class="step">
    <div class="container">
      <div class="step-title">{{ $t("step.title") }}</div>
      <div class="step-item">
        <span :class="{ active: step == 1, prev: step > 1 }">
          1. {{ $t("step.step1") }}
        </span>
        <span :class="{ prev: step > 1 }">▶</span>
        <span :class="{ active: step == 2, prev: step > 2 }">
          2. {{ $t("step.step2") }}
        </span>
        <span :class="{ prev: step > 2 }">▶</span>
        <span :class="{ active: step == 3, prev: step > 3 }">
          3. {{ $t("step.step3") }}
        </span>
        <span :class="{ prev: step > 3 }">▶</span>
        <span :class="{ active: step == 4, prev: step > 4 }">
          4. {{ $t("step.step4") }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Step",

  props: {
    step: Number,
  },

  data() {
    return {};
  },
};
</script>
<style scoped lang="less">
.step {
  padding: 12px 0;
  height: 96px;
  background: #fcb92f;

  .step-title {
    font-weight: 500;
    font-size: 32px;
    color: #fff;
  }

  .step-item {
    font-size: 17px;

    span {
      margin-right: 22px;
      color: #848484;

      &.active,
      &.prev {
        color: #fff;
      }
    }
  }
}

@media (max-width: 1000px) {
  .step {
    height: 88px;

    .step-title {
      font-size: 28px;
    }

    .step-item {
      font-size: 16px;

      span {
        display: none;

        &.active {
          display: block;
        }
      }
    }
  }
}
</style>