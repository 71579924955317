<template>
  <Step :step="1" />
  <div class="info container page">
    <div class="box">
      <div class="form-group">
        <HeaderStep :title="$t('minStep.step1')" :step="1"></HeaderStep>

        <div class="form-group-title" id="s1">{{ $t("info.detail") }}</div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.type }" id="s2">
            <label class="form-field">
              {{ $t("info.type") }}
              <span v-if="error.type">{{ error.type }}</span>
            </label>

            <Multiselect
              class="form-input"
              v-model="detail.type"
              :options="options.type"
              @select="check('type')"
              :allowEmpty="false"
            />

            <input type="hidden" v-model="detail.type" />
          </div>

          <div class="form-item" :class="{ error: error.carType }" id="s3">
            <label class="form-field">
              {{ $t("info.carType") }}
              <span v-if="error.carType">{{ error.carType }}</span>
            </label>

            <Multiselect
              class="form-input"
              v-model="detail.carType"
              :options="options.carType"
              @select="check('carType')"
              :allowEmpty="false"
            />

            <input type="hidden" v-model="detail.carType" />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.brand }" id="s4">
            <label class="form-field">
              {{ $t("info.brand") }}
              <span v-if="error.brand">{{ error.brand }}</span>
            </label>

            <input
              class="form-input"
              v-model="detail.brand"
              @blur="check('brand')"
            />
          </div>

          <div class="form-item" :class="{ error: error.model }" id="s5">
            <label class="form-field">
              {{ $t("info.model") }}
              <span v-if="error.model">{{ error.model }}</span>
            </label>

            <input
              class="form-input"
              v-model="detail.model"
              @blur="check('model')"
            />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.year }" id="s6">
            <label class="form-field">
              {{ $t("info.year") }}
              <span v-if="error.year">{{ error.year }}</span>
            </label>

            <input
              class="form-input"
              v-model="detail.year"
              @blur="check('year')"
              type="number"
            />
          </div>

          <div class="form-item" :class="{ error: error.date }" id="s7">
            <label class="form-field">
              {{ $t("info.date") }}
              <span v-if="error.date">{{ error.date }}</span>
            </label>

            <flatPickr
              :config="Object.assign({ maxDate: 'today' }, config)"
              class="form-input"
              @on-change="check('date')"
              v-model="detail.date"
            />
          </div>
        </div>

        <div class="form-line">
          <div class="form-item" :class="{ error: error.displacement }" id="s8">
            <label class="form-field">
              {{ $t("info.displacement") }}
              <span v-if="error.displacement">{{ error.displacement }}</span>
            </label>

            <input
              class="form-input"
              v-model="detail.displacement"
              @blur="check('displacement')"
              type="number"
            />
          </div>

          <div
            v-if="detail.type == $t('info.options.type.all')"
            class="form-item"
            :class="{ error: error.money }"
            id="s9"
          >
            <label class="form-field">
              {{ $t("info.money") }}
              <span v-if="error.money">{{ error.money }}</span>
            </label>

            <input
              class="form-input"
              v-model="detail.money"
              @blur="check('money')"
              type="number"
            />
          </div>

          <div
            v-if="
              detail.carType == $t('info.options.carType.business') &&
              detail.type != $t('info.options.type.all')
            "
            class="form-item"
            :class="{ error: error.quality }"
            id="s10"
          >
            <label class="form-field">
              {{ $t("info.quality") }}
              <span v-if="error.quality">{{ error.quality }}</span>
            </label>

            <input
              class="form-input"
              v-model="detail.quality"
              @blur="check('quality')"
              type="number"
            />
          </div>
        </div>

        <div class="form-line">
          <div
            v-if="
              detail.carType == $t('info.options.carType.business') &&
              detail.type == $t('info.options.type.all')
            "
            class="form-item"
            :class="{ error: error.quality }"
            id="s10"
          >
            <label class="form-field">
              {{ $t("info.quality") }}
              <span v-if="error.quality">{{ error.quality }}</span>
            </label>

            <input
              class="form-input"
              v-model="detail.quality"
              @blur="check('quality')"
              type="number"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="form-action">
      <button class="btn" @click="next">{{ $t("info.next") }}</button>
    </div>
  </div>
</template>
<script>
import Step from "../../components/Step";
import HeaderStep from "../../components/HeaderStep";
import { mapState } from "vuex";
import util from "../../helper/util";
import Multiselect from "@vueform/multiselect";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { MandarinTraditional } from "flatpickr/dist/l10n/zh-tw";
import { Mandarin } from "flatpickr/dist/l10n/zh";

import "@vueform/multiselect/themes/default.css";

import { scroller } from "vue-scrollto/src/scrollTo";
const scrollTo = scroller();

export default {
  name: "Info",

  components: {
    Step,
    Multiselect,
    HeaderStep,
    flatPickr,
  },

  computed: {
    ...mapState({
      cache: (state) => state.detail.data.detail,
    }),
  },

  data() {
    return {
      detail: {
        type: "",
        carType: "",
        brand: "",
        model: "",
        money: "",
        displacement: "",
        quality: "",
        year: "",
        date: "",
        discount: "",
      },

      config: {
        disable: ["2100-01-01"],
        wrap: true,
        altInput: true,
        altFormat: "d/m/Y",
        locale: localStorage.lang == "zhHK" ? MandarinTraditional : Mandarin,
        minDate: "today",
        maxDate: (() => {
          const month = new Date().getMonth() + 3;
          const year = new Date().getFullYear();
          const day = new Date().getDate() - 1;
          return year + "-" + month + "-" + day;
        })(),
      },

      options: {
        type: [
          this.$t("info.options.type.all"),
          this.$t("info.options.type.other"),
        ],

        carType: [
          this.$t("info.options.carType.personal"),
          this.$t("info.options.carType.business"),
        ],
      },

      error: {
        type: false,
        carType: false,
        brand: false,
        model: false,
        money: false,
        displacement: false,
        quality: false,
        year: false,
        date: false,
      },
    };
  },

  mounted() {
    this.detail = Object.assign(this.detail, this.cache);
  },

  methods: {
    check(field) {
      const self = this;

      let rules = {
        type: {
          model: this.detail.type,
          ref: "#s1",
          message: this.$t("info.verify.type"),
          fn: "require",
        },
        carType: {
          model: this.detail.carType,
          ref: "#s2",
          message: this.$t("info.verify.carType"),
          fn: "require",
        },
        brand: {
          model: this.detail.brand,
          ref: "#s3",
          message: this.$t("info.verify.brand"),
          fn: "require",
        },
        model: {
          model: this.detail.model,
          ref: "#s4",
          message: this.$t("info.verify.model"),
          fn: "alphaDash",
        },
        year: {
          model: this.detail.year,
          ref: "#s5",
          message: this.$t("info.verify.year"),
          fn: "number",
        },
        date: {
          model: this.detail.date,
          ref: "#s6",
          message: this.$t("info.verify.date"),
          fn: "require",
        },
        displacement: {
          model: this.detail.displacement,
          ref: "#s7",
          message: this.$t("info.verify.displacement"),
          fn: "number",
        },
        money: {
          model: this.detail.money,
          ref: "#s8",
          message: this.$t("info.verify.money"),
          fn: "number",
          before() {
            return self.detail.type == self.$t("info.options.type.all");
          },
        },
        quality: {
          model: this.detail.quality,
          ref: "#s9",
          message: this.$t("info.verify.quality"),
          fn: "number",
          before() {
            return (
              self.detail.carType == self.$t("info.options.carType.business")
            );
          },
        },
      };

      if (field) {
        let rule = rules[field];
        rules = {};
        rules[field] = rule;
      }

      let result = true;
      for (let key in rules) {
        let rule = rules[key];

        if (rule.before && !rule.before()) {
          this.error[key] = false;
          continue;
        }

        if (!util[rule.fn](rule.model)) {
          this.error[key] = rule.message;

          if (!field && result) {
            scrollTo(rule.ref);
          }

          result = false;
        } else {
          this.error[key] = false;
        }
      }

      return result;
    },

    next() {
      if (!this.check()) {
        return;
      }

      this.$store.commit("detail/setDetail", this.detail);
      this.$store.commit("detail/setCache");
      this.$router.push("/driver");
    },
  },
};
</script>
<style scoped lang="less">
.model {
  position: relative;

  span {
    padding: 0 10px;
    height: 36px;
    line-height: 36px;
    font-size: 16px;
    position: absolute;
    background: #eee;
  }

  input {
    padding-left: 80px !important;
  }
}
</style>