<template>
  <Step :step="1" />

  <div class="page container" id="Error">
    <div class="box">
      <p>
        因閣下之申請已超出本平台所能處理而未能接納。如欲索取是項報價，請致電服務熱線2375
        3456或電郵至服務支援 cssupport@insurfun.com。我們職員會盡快與閣下聯絡。
      </p>
      <p v-if="code"><a :href="`/?code=${code}`">點擊這裡</a> 重新填寫</p>
    </div>
    <div class="detail-action">
      <button class="btn btn-prev" @click="prev">
        {{ $t("info.back") }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import Step from "../../components/Step";

export default {
  name: "Error",

  components: {
    Step,
  },

  computed: {
    ...mapState({
      cache: (state) => state.detail.data,
    }),
  },

  mounted() {
    this.init();
  },

  data() {
    return {
      data: {},
      code: "",
    };
  },

  methods: {
    async init() {
      let data = {};

      this.data = Object.assign(this.data, this.cache);

      const code = window.localStorage.getItem("code");
      this.code = code;
      if (!code) {
        data = await this.createOrder();
      } else {
        data = await this.updateOrder();
      }

      const order = data.data;
      this.code = order.code;
      this.id = order.id;

      window.localStorage.setItem("code", this.code);

      this.sendEmail();
    },

    async createOrder() {
      const { data } = await this.$fetch("error", {
        method: "POST",
        body: this.data,
      });

      return data;
    },

    async updateOrder() {
      const { data } = await this.$fetch(`order/error/${this.code}`, {
        method: "PUT",
        body: this.data,
      });

      return data;
    },

    sendEmail() {
      this.$fetch(`error/email/${this.id}`);
    },

    prev() {
      this.$router.back();
    },
  },
};
</script>
<style scoped lang="less">
</style>