<template>
  <div class="home page container">
    <template v-if="status">
      <div class="landing">
        <img
          src="@/assets/images/home-desktop.png"
          class="landing-img desktop"
        />
        <img src="@/assets/images/home-tablet.png" class="landing-img tablet" />
        <img src="@/assets/images/home-mobile.png" class="landing-img mobile" />
      </div>

      <div class="box">
        <div class="form-group">
          <div class="form-item">
            <template v-if="referer">
              <label class="form-field">{{ $t("home.referer") }}</label>
              <input class="form-input" readonly v-model="referer" />
            </template>
          </div>
        </div>
        <div class="action">
          <button class="btn" @click="next">{{ $t("home.submit") }}</button>
        </div>
      </div>
    </template>
    <template v-else>
      Only query string in listed referers are allow to access this product
    </template>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "Home",

  components: {},

  computed: {
    ...mapState({
      detail: (state) => state.detail.data,
    }),
  },

  data() {
    return {
      status: true,
      referer: null,
    };
  },

  mounted() {
    this.init();
  },

  methods: {
    init() {
      this.getReferer();
    },

    getReferer() {
      const allow = process.env.VUE_APP_REFERER_ALLOW.split(",");
      const referer = this.$route.query.referer;

      if (referer && !allow.includes(referer)) {
        this.status = false;
        return;
      }

      this.referer = referer;
    },

    next() {
      this.$store.commit("detail/setReferer", this.referer);
      this.$store.commit("detail/setCache");

      this.$router.push("/info");
    },
  },
};
</script>
<style scoped lang="less">
.home {
  .form-field {
    font-size: 14px;
  }

  .action {
    margin-top: 15px;

    .btn {
      width: 94px;
      height: 34px;
      line-height: 18px;
      border-radius: 5px;
    }
  }

  .landing-img {
    width: 100%;
    display: none;
  }

  .desktop {
    display: block;
  }

  .table {
    max-width: 100%;
    overflow: auto;
  }

  .plan {
    margin: 0 0 30px 0px;
    min-width: 665px;
    text-align: center;
    border-collapse: collapse;

    th {
      max-width: 110px;
      font-weight: 400;
      border: 1px solid #e2e2e2;
      padding: 8px 20px;
      background: #f9fafb;
    }

    td {
      border: 1px solid #e2e2e2;
      padding: 8px 20px;
    }
  }

  /deep/.form-input {
    width: 230px !important;
  }

  @media (max-width: 990px) {
    .desktop {
      display: none;
    }

    .tablet {
      display: block;
    }
  }

  @media (max-width: 500px) {
    .desktop {
      display: none;
    }

    .tablet {
      display: none;
    }

    .mobile {
      display: block;
    }
  }
}
</style>