<template>
  <div class="header-step">
    <div class="border"></div>
    <div class="step" :class="{ active: step == 1 }">
      <div class="step-page-title" v-if="step == 1">{{ title }}</div>
    </div>
    <div class="step" :class="{ active: step == 2 }">
      <div class="step-page-title" v-if="step == 2">{{ title }}</div>
    </div>
    <div class="step" :class="{ active: step == 3 }">
      <div class="step-page-title" v-if="step == 3">{{ title }}</div>
      <div class="step-page-title last" v-if="step == 4">{{ title }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderStep",

  props: {
    title: String,
    step: [String, Number],
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="less" scoped>
.header-step {
  height: 50px;
  margin-top: 20px;
  position: relative;

  .step-page-title {
    position: absolute;
    top: -35px;
    font-size: 20px;
    color: #4a8bcb;
    font-weight: bolder;

    &.last {
      right: 0;
    }
  }

  .border {
    width: 100%;
    top: 22px;
    border-bottom: 2px solid #fcb92f;
    position: absolute;
  }

  .step {
    width: 33.33333%;
    position: relative;
    display: inline-block;

    &::before {
      width: 20px;
      height: 20px;
      content: "";
      display: block;
      position: absolute;
      background: #fcb92f;
      border-radius: 10px;
      left: -5px;
      top: 0;
    }

    &.active {
      &::before {
        background: url("../../assets/images/tick.png") center no-repeat;
        background-size: 20px;
      }
    }

    &:last-child {
      &::after {
        width: 20px;
        height: 20px;
        content: "";
        display: block;
        position: absolute;
        background: #fcb92f;
        border-radius: 10px;
        right: -5px;
      }
    }
  }
}
</style>
