export default {
    state: {
        data: {
            referer: null,

            detail: {
                type: "",
                carType: "",
                brand: "",
                model: "",
                money: "",
                displacement: "",
                quality: "",
                year: "",
                date: "",
                discount: "",
                setterType: "person"
            },

            driver: {
                old: "",
                years: "",
                job: ""
            },

            contact: {
                name: "",
                email: "",
                mobile: ""
            },

            agree: {
                trem: true,
                privacy: true,
                notice: true
            }
        },
    },

    mutations: {
        ["detail/setData"](state, data) {
            state.data = Object.assign(state.data, data);
        },

        ["detail/setDetail"](state, detail) {
            state.data.detail = Object.assign(state.data.detail, detail);
        },

        ["detail/setReferer"](state, referer) {
            state.data.referer = referer;
        },

        ["detail/setDriver"](state, driver) {
            state.data.driver = Object.assign(state.data.driver, driver);
        },

        ["detail/setContact"](state, contact) {
            state.data.contact = Object.assign(state.data.contact, contact);
        },

        ["detail/setAgree"](state, agree) {
            state.data.agree = Object.assign(state.data.agree, agree);
        },

        ["detail/setCache"](state) {
            window.sessionStorage.setItem("detail", JSON.stringify(state.data));
        }
    },

    actions: {
        ["detail/getCache"]({ commit }) {
            const cache = window.sessionStorage.getItem("detail");
            if (!cache) {
                return false;
            }

            const data = JSON.parse(cache);
            commit("detail/setData", data);
        },

        ["detail/clearCache"]() {
            window.sessionStorage.removeItem("detail");
        },

        ["detail/getData"]({ state }) {
            return state.data;
        }
    }
};
